





















































































import Vue from "vue";
import VModal from "vue-js-modal";
import AddAnnouncementsForm from "./components/AddAnnouncementsForm.vue";
import DeleteAnnouncementForm from "./components/DeleteAnnouncementForm.vue";
import UpdateAnnouncementForm from "./components/UpdateAnnouncementForm.vue";
import CustomRow from "./components/CustomRow.vue";
import { ANNOUNCEMENT_COLUMNS } from "@/constants";
import { Dropdown, CustomInput, CustomTable } from "@/components";

Vue.use(VModal);

export default Vue.extend({
  name: "AnnouncementList",
  components: {
    AddAnnouncementsForm,
    DeleteAnnouncementForm,
    UpdateAnnouncementForm,
    CustomRow,
    Dropdown,
    CustomInput,
    CustomTable,
  },
  data() {
    return {
      isAddAnnouncementVisible: false,
      columns: ANNOUNCEMENT_COLUMNS,
      sortableColumns: ANNOUNCEMENT_COLUMNS.filter(
        (column) => !!column.sortable
      ),
      announcements: [
        {
          code: "00039 30399",
          announcement: {
            announcementName: "Announcement Title",
            announcementDetail:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do ...",
          },
          startDate: {
            date: "20 OCT 2021",
            time: "09:00 GMT",
          },
          startEnd: {
            date: "20 OCT 2021",
            time: "09:00 GMT",
          },
          status: "INACTIVE",
          actions: "",
          date: "sdfsdf",
          time: "dfsfsf",
        },
        {
          code: "00039 30399",
          announcement: {
            announcementName: "Announcement Title",
            announcementDetail:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do ...",
          },
          startDate: {
            date: "20 OCT 2021",
            time: "09:00 GMT",
          },
          startEnd: {
            date: "20 OCT 2021",
            time: "09:00 GMT",
          },
          status: "ACTIVE",
          actions: "",
        },
        {
          code: "00039 30399",
          announcement: {
            announcementName: "Announcement Title",
            announcementDetail:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do ...",
          },
          startDate: {
            date: "20 OCT 2021",
            time: "09:00 GMT",
          },
          startEnd: {
            date: "20 OCT 2021",
            time: "09:00 GMT",
          },
          status: "INACTIVE",
          actions: "",
          date: "sdfsdf",
          time: "dfsfsf",
        },
        {
          code: "00039 30399",
          announcement: {
            announcementName: "Announcement Title",
            announcementDetail:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do ...",
          },
          startDate: {
            date: "20 OCT 2021",
            time: "09:00 GMT",
          },
          startEnd: {
            date: "20 OCT 2021",
            time: "09:00 GMT",
          },
          status: "ACTIVE",
          actions: "",
        },
        {
          code: "00039 30399",
          announcement: {
            announcementName: "Announcement Title",
            announcementDetail:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do ...",
          },
          startDate: {
            date: "20 OCT 2021",
            time: "09:00 GMT",
          },
          startEnd: {
            date: "20 OCT 2021",
            time: "09:00 GMT",
          },
          status: "INACTIVE",
          actions: "",
          date: "sdfsdf",
          time: "dfsfsf",
        },
        {
          code: "00039 30399",
          announcement: {
            announcementName: "Announcement Title",
            announcementDetail:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do ...",
          },
          startDate: {
            date: "20 OCT 2021",
            time: "09:00 GMT",
          },
          startEnd: {
            date: "20 OCT 2021",
            time: "09:00 GMT",
          },
          status: "ACTIVE",
          actions: "",
        },
        {
          code: "00039 30399",
          announcement: {
            announcementName: "Announcement Title",
            announcementDetail:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do ...",
          },
          startDate: {
            date: "20 OCT 2021",
            time: "09:00 GMT",
          },
          startEnd: {
            date: "20 OCT 2021",
            time: "09:00 GMT",
          },
          status: "INACTIVE",
          actions: "",
        },
        {
          code: "00039 30399",
          announcement: {
            announcementName: "Announcement Title",
            announcementDetail:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do ...",
          },
          startDate: {
            date: "20 OCT 2021",
            time: "09:00 GMT",
          },
          startEnd: {
            date: "20 OCT 2021",
            time: "09:00 GMT",
          },
          status: "ACTIVE",
          actions: "",
        },
      ],
    };
  },
  methods: {
    showAddAnnouncementModal() {
      this.$modal.show("add-announcement-modal");
    },
    hideAddAnnouncementModal() {
      this.$modal.hide("add-announcement-modal");
    },
    showDeleteAnnouncementModal() {
      this.$modal.show("delete-announcement-modal");
    },

    hideDeleteAnnouncementModal() {
      this.$modal.hide("delete-announcement-modal");
    },
    showUpdateAnnouncementModal() {
      this.$modal.show("update-announcement-modal");
    },
    hideUpdateAnnouncementModal() {
      this.$modal.hide("update-announcement-modal");
    },
  },
});
