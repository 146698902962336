


















































import Vue from "vue";
import VTooltip from "v-tooltip";

Vue.use(VTooltip);

export default Vue.extend({
  name: "CustomRow",
  props: {
    field: String,
    row: Object,
    showRemoveAnnouncement: Function,
    showUpdateAnnouncement: Function,
  },
  computed: {
    fieldValue(): string {
      return this.row[this.field];
    },
  },
  methods: {
    getAnnouncement(key: string): string {
      switch (key) {
        case "announcementName":
          return this.row[this.field].announcementName;
        case "announcementDetail":
          return this.row[this.field].announcementDetail;
        default:
          return "";
      }
    },
    getDate(key: string): string {
      switch (key) {
        case "date":
          return this.row[this.field].date;
        case "time":
          return this.row[this.field].time;
        default:
          return "";
      }
    },
  },
});
