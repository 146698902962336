



















































import Vue from "vue";
import { Dropdown, CustomInput, SvgIcon, CustomTextArea } from "@/components";
export default Vue.extend({
  name: "AddAnnouncementForm",
  components: {
    Dropdown,
    CustomInput,
    SvgIcon,
    CustomTextArea,
  },
  computed: {
    statusOptions(): Array<string> {
      return ["Active", "Inactive"];
    },
  },
});
